const Footer = () => {
  return (
    <div>
      <div className="container_fluid ">
        <hr className="hr-a footer_hr" />
      </div>
      <section className="top_section_outer">
        <nav className="navbar navbar-expand-lg">
          <div className="container-fluid">
            <div className="nav-header">
              <a className="navbar-brand">
                <p className="copy-right mb-0">© ROCKY MINER </p>
              </a>
            </div>
            <div className="nav-right">
              <div className="topbar-button">
                <div className="copy-right_list_outer">
                  <ul className="footer_copyright_list">
                    <li className="tumama">
                      <a
                        target={"_blank"}
                        href="https://drive.google.com/file/d/1EF0e-yPVkNg5SMmb-eWW_C57q1I3DQIb/view?usp=sharing"
                      >
                        Whitepaper
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://t.me/RockyMiner"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src="./images/telegramwhite.png" alt="telegram" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </section>
    </div>
  );
};

export default Footer;
